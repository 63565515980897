.dark {
  color: #FFFFFF !important;
}

.charts-blk {
  min-height: 50vh;
  border: 1px solid #DDDDDD;
  background-color: #FFFFFF !important;
}

.charts-blk-dark {
  min-height: 50vh;
  border: 1px solid #DDDDDD;
  background-color: #0B0B0B !important;
}

.chart-sub-blk {
  padding-top: 15px;
}

.chart-main-blk {
  cursor: grab;
}

.chart-main-blk-dark {
  background-color: #0B0B0B !important;
  cursor: grab;
}

.chart-blk {
  display: table;
}

.chart-cell-blk {
  display: table-cell;
}

.chart-padding-blk {
  background-color: #00A6EF;
  width: 10px;
  height: 40px;
}

.chart-blk-header {
  vertical-align: middle;
  padding-left: 0.5em;
  color: #00A6EF;
  font-weight: 600;
}

.chart-settings-icon {
  float: right;
  cursor: pointer;
  padding-top: 0.3em;
  padding-right: 1em;
}

.chart-chart {
  height: 45vh;
}

.chart-blk-title {
  font-weight: bold;
  float: left;
  display: inline-block;
  padding-top: 1.5em;
  max-width: 90%;
}

.chartDrop {
  float: left;
  padding-left: 5px;
  color: #373737;
  padding-top: 25px;
}
