.coming-soon-blk {
  background-color: #FFFFFF;
  height: 90vh;
  display:flex;
  align-items:center;
  justify-content:center;
  text-align: center;
  margin-left: 1.3em;
  width: calc(100% - 40px) !important;
  border: 1px solid #DDDDDD;
}

.coming-soon-blk-dark {
  background-color: #0B0B0B;
  height: 90vh;
  display:flex;
  align-items:center;
  justify-content:center;
  text-align: center;
  margin-left: 1.3em;
  width: calc(100% - 40px) !important;
  border: 1px solid #DDDDDD;
}

.coming-message {
  color: #C4C4C4;
}
