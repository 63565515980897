body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-y: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
/* Chivo */

@font-face {
  font-family: "Chivo";
  src: local("Chivo"), url(./assets/fonts/Chivo-Italic.ttf) format("truetype");
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: "Chivo";
  src: local("Chivo"), url(./assets/fonts/Chivo-Regular.ttf) format("truetype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Chivo";
  src: local("Chivo"), url(./assets/fonts/Chivo-Bold.ttf) format("truetype");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Chivo";
  src: local("Chivo"), url(./assets/fonts/Chivo-BoldItalic.ttf) format("truetype");
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: "Chivo";
  src: local("Chivo"), url(./assets/fonts/Chivo-Black.ttf) format("truetype");
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: "Chivo";
  src: local("Chivo"), url(./assets/fonts/Chivo-BlackItalic.ttf) format("truetype");
  font-weight: 900;
  font-style: italic;
}

/* Graphik */

@font-face {
  font-family: "Graphik";
  src: local("Graphik"), url(./assets/fonts/GraphikThin.otf) format("truetype");
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: "Graphik";
  src: local("Graphik"), url(./assets/fonts/GraphikThinItalic.otf) format("truetype");
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: "Graphik";
  src: local("Graphik"), url(./assets/fonts/GraphikExtralight.otf) format("truetype");
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: "Graphik";
  src: local("Graphik"), url(./assets/fonts/GraphikExtralightItalic.otf) format("truetype");
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: "Graphik";
  src: local("Graphik"), url(./assets/fonts/GraphikLight.otf) format("truetype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Graphik";
  src: local("Graphik"), url(./assets/fonts/GraphikLightItalic.otf) format("truetype");
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: "Graphik";
  src: local("Graphik"), url(./assets/fonts/GraphikRegularItalic.otf) format("truetype");
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: "Graphik";
  src: local("Graphik"), url(./assets/fonts/GraphikRegular.otf) format("truetype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Graphik";
  src: local("Graphik"), url(./assets/fonts/GraphikMedium.otf) format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Graphik";
  src: local("Graphik"), url(./assets/fonts/GraphikMediumItalic.otf) format("truetype");
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: "Graphik";
  src: local("Graphik"), url(./assets/fonts/GraphikSemibold.otf) format("truetype");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Graphik";
  src: local("Graphik"), url(./assets/fonts/GraphikSemiboldItalic.otf) format("truetype");
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: "Graphik";
  src: local("Graphik"), url(./assets/fonts/GraphikBold.otf) format("truetype");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Graphik";
  src: local("Graphik"), url(./assets/fonts/GraphikBoldItalic.otf) format("truetype");
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: "Graphik";
  src: local("Graphik"), url(./assets/fonts/GraphikBlack.otf) format("truetype");
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: "Graphik";
  src: local("Graphik"), url(./assets/fonts/GraphikBlackItalic.otf) format("truetype");
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: "Graphik";
  src: local("Graphik"), url(./assets/fonts/GraphikSuper.otf) format("truetype");
  font-weight: 950;
  font-style: normal;
}

@font-face {
  font-family: "Graphik";
  src: local("Graphik"), url(./assets/fonts/GraphikSuperItalic.otf) format("truetype");
  font-weight: 950;
  font-style: italic;
}
