.dash-data-blk .padding-blk, .dash-data-blk-dark .padding-blk {
  background-color: #00A6EF;
  width: 8px;
  height: 33px;
}

.inline-blk {
  display: inline-block;
}

.dash-data-blk .main-blk-dark, .dash-data-blk-dark .main-blk-dark {
  background-color: #0B0B0B !important;
}

.dash-data-blk {
  min-height: 595px;
  padding-top: 20px;
  border: 1px solid #DDDDDD;
  background-color: #FFFFFF !important;
}

.dash-data-blk-dark {
  min-height: 595px;
  padding-top: 20px;
  border: 1px solid #DDDDDD;
  background-color: #242424 !important;
}

.dash-data-blk .main-blk, .dash-data-blk-dark .main-blk {
  cursor: grab;
}

.table-blk {
  display: table;
}

.table-cell-blk {
  display: table-cell;
}

.dash-data-blk .blk-header, .dash-data-blk-dark .blk-header {
  vertical-align: middle;
  padding-left: 0.5em;
  color: #00A6EF;
  font-weight: 600;
  font-size: 28px;
  font-style: normal;
  line-height: 31px;
}

.dark {
  color: #FFFFFF !important;
}

.settings-icon {
  float: right;
  cursor: pointer;
  padding-top: 0.3em;
}

.ddata-ddata {
  height: 450px;
  text-align: center;
  padding-left: 0px !important;
  overflow-y: hidden !important;
}

.ddata-ddata .data-info-blk {
  display: inline-block;
  min-width: 40px;
  height: 127px;
  vertical-align: text-top;
}

.ddata-ddata .data-info-value-rate {
  font-weight: 600;
  font-size: 1em;
  font-family: "Graphik" !important;
}

.ddata-ddata .data-info-value-measure {
  font-weight: 400;
  font-size: 16px;
  padding-left: 5px;
  font-family: "Chivo" !important;
}

.ddata-ddata .data-info-logo {
  height: 40px;
}

.dash-data-blk .data-info-value-blk {
  color: #005DAA;
  padding-top: 10px;
  line-break: anywhere;
}

.dash-data-blk-dark .data-info-value-blk {
  color: #FFFFFF;
  padding-top: 10px;
  line-break: anywhere;
}

.ddata-ddata .data-info-blk-value {
  width: calc(100% - 40px) !important;
  display: inline-block;
  height: 80px;
}

.dash-data-blk .table-blk, .dash-data-blk-dark .table-blk {
  padding-left: 10px;
}

.dash-data-blk .settings-icon, .dash-data-blk-dark .settings-icon {
  padding-right: 10px;
}

.dash-data-blk .cdn-blk-title-right, .dash-data-blk-dark .cdn-blk-title-right {
  float: left;
  font-weight: bold;
  padding-left: 0.5em;
  padding-bottom: 0em;
  margin-bottom: 0em !important;
  display: inline-block;
  font-family: "Chivo" !important;
  text-overflow: ellipsis;
  overflow: hidden;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
}

#dataDrop {
  float: left;
  display: inline-block;
  padding-right: 5px;
  padding-left: 5px;
  color: #373737;
}

.data-info-label {
  font-family: "Chivo" !important;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
}

@media (max-width: 767.98px) {
  .dash-data-blk .cdn-blk-title-right {
    font-size: 15px !important;
  }
}
