.dark {
  color: #FFFFFF !important;
}

.contacts-blk {
  min-height: 400px;
  border: 1px solid #DDDDDD;
  background-color: #FFFFFF !important;
}

.contacts-blk-dark {
  min-height: 400px;
  border: 1px solid #DDDDDD;
  background-color: #242424 !important;
}

.contact-main-blk {
  cursor: grab;
}

.contact-main-blk-dark {
  background-color: #242424 !important;
  cursor: grab;
}

.contact-sub-blk {
  padding-top: 20px;
}

.dash-contact-blk {
  display: table;
}

.contact-cell-blk {
  display: table-cell;
}

.contacts-blk .contact-padding-blk, .contacts-blk-dark .contact-padding-blk {
  background-color: #005DAA;
  width: 8px;
  height: 33px;
}

.contact-blk-header {
  vertical-align: middle;
  padding-left: 0.5em;
  color: #005DAA;
  font-weight: 600;
}

.contacts-blk .settings-icon, .contacts-blk-dark .settings-icon {
  float: right;
  cursor: pointer;
  padding-top: 0.5em;
  padding-right: 10px;
}

.dcontact-contact {
  height: 300px;
}

.contacts-blk .dcontact-contact {
  color: #373737;
}

.contact-blk-title {
  font-weight: bold;
  float: left;
  display: inline-block;
  padding-top: 1.5em;
  max-width: 90%;
}

.dcontact-contact img {
  float: right;
  padding-left: 10px;
  padding-bottom: 0.8em;
  width: 35px;
  cursor: pointer;
}

.contacts-blk-dark .contact-info {
  color: #FFFFFF;
}

.contact-info {
  padding-bottom: 10px;
}

.contacts-blk .contact-info p, .contacts-blk-dark .contact-info p {
  line-height: 0.8em;
  font-size: 14px;
  font-family: "Chivo" !important;
}

.department-name {
  font-weight: 400;
  font-size: 14px;
  font-style: normal;
  font-family: "Chivo" !important;
  color: #005DAA;
}

.info-title {
  font-weight: 800;
  font-size: 18px;
}
