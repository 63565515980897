.data_big_chart_container {
  width: 100%;
  min-height: 400px;
  background-color: #fff;
  border: 1px solid #dddddd;
  margin: auto;
  margin-top: 50px;
  padding: 15px;
  border-radius: 8px;
  margin-bottom: 20px;
  /* margin-right: 10px; */
}
.data_big_chart_container.hide {
  display: none !important;

}
.data_big_chart_container.dark {
  background-color: #000;
}
.data_big_chart_container .title {
  border-left: 10px solid #00a6ef;
  color: #00a6ef;
  font-weight: bold;
  font-size: 25px;
  padding-left: 10px;
  margin-bottom: 25px;
}
.data_chart_container .space_container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
#space_modal .modal_title {
  color: #005daa;
  border-left: 10px solid #005daa;
  padding-left: 10px;
  font-weight: bold;
}
#space_modal .main_block {
  width: 80px;
  height: 35px;
  background-color: #005daa;
  color: #fff;
  font-size: 13px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  border-radius: 3px;
}
#space_modal .modal-header {
}
#space_modal .main_block_container_space {
  padding-bottom: 20px;
  /* border-bottom: 1px solid #dee2e6 !important; */
  padding-left: 1rem;
}
#space_modal .main_block_space_underline {
  width: 96%;
  height: 1px;
  background-color: #dee2e6;
  margin-top: 20px;

  margin: auto;
  padding-left: 1rem;
  padding-right: 1rem;
}
.space_modal_content_container {
  display: flex;
}
.space_modal_left_side_header {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 5px;
}
.space_modal_left_side_header div:last-child {
  font-weight: bold;
  font-size: 15px;
  font-family: "Chivo";
  margin-left: 5px;
  margin-top: 2px;
}
.left-side_space_modal {
  width: fit-content;
  background-color: #f8f8f8;
  padding: 12px;
}
.space_modal_left_side_options {
  color: #0b0b0b;

  padding: 8px 0px;
  padding-right: 8px;
  font-family: "Chivo";
  border-bottom: 1px solid #dddddd;
  cursor: pointer;
}
.space_modal_left_side_options.active {
  color: #005daa;
  font-weight: bold;
}
.right-side_space_modal {
  width: 100%;
}
.right-side_space_modal .header_space {
  display: flex !important;
  justify-content: flex-start;
  align-items: center;
  margin-top: 8px;
  margin-bottom: 20px;
}

.right-side_space_modal .header_space > div:first-child {
  width: 75px;
  border-right: 1px solid #dddddd;
  font-size: 15px;
  font-weight: bolder;
  font-family: "Chivo";
}
.items_options_space {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 10px;
  margin-top: 10px;
  overflow-y: auto;
  height: 250px;
}
.item_container_space {
  padding: 8px;
  border: 1px solid #dddddd;
  border-radius: 5px;
  cursor: pointer;
}
.item_container_space.active {
  background-color: #005daa;
  color: #fff;
}
.item_container_space:hover {
  background-color: #005daa;
  transition: 0.5s ease;
  color: #fff;
}
.te:hover {
  background-color: #005daa;
  color: #fff;
}
.item_container_space:hover svg,
.item_container_space.active svg {
  stroke: #fff;
}
.item_title_space div {
  margin-left: 5px;
  font-family: "Chivo";
  font-weight: bold;
  font-size: 12px;
}
.item_subtitle_space {
  font-family: "Chivo";
  font-size: 12px;
}
.item_title_space {
  display: flex;
  align-items: center;
}
.right-side_space_modal .header_space > div:first-child svg {
  margin-right: 5px;
}
.right-side_space_modal .header_space > div:last-child {
  display: flex;
  align-items: center;
  padding-left: 15px;
  font-size: 15px;
}
/* [data-tooltip]:before {
  position: absolute;
  content: attr(data-tooltip);
  top: -80px;
  opacity: 0;
  background-color: rgba(0, 0, 0, 0.8);
  color: #fff;
  border-radius: 8px;
  padding: 10px;
}

[data-tooltip]:hover:before {
  opacity: 1;
} */
.bulding-blocks-container {
  width: auto;
}
.bulding-blocks-container div {
  margin-bottom: 8px;
}
.paging-space-blk-data{
  display: flex !important;
  flex-wrap: wrap !important;
  justify-content: flex-start;
  align-items: center;
}
.middle-padding-space-blk-data {
  /* width: 1px;
  height: 100px;
  border-left: 1px solid #DDDDDD;
  display: inline; */
  /* vertical-align: super; */
  border: 1px solid #DDDDDD;
  margin-left: 20px;
  margin-right: 20px;
}
.middle_line {
  width: 2px;
  height: 30px;
  background-color: #DDDDDD;
  margin-left: 20px;
  margin-right: 20px;
}
@media only screen and (max-width: 800px) {
  .charts_container_mobile {
    display: flex;
    justify-content: center;
    width: 90%;
    margin: auto;
  }
  .items_options_space {
    grid-template-columns: repeat(2, 1fr);
  }
  .data_chart_container {
    width: 90% !important;
    /* margin-right: 18px; */
    border-radius: 8px;
    /* margin: auto !important; */
    /* margin: auto; */
    margin: auto;
    margin-top: 30px;
  }
  .data_chart_container .space_container {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-bottom: 30px;
  }
  .data_chart_container .title {
    font-size: 18px;
  }
}
@media only screen and (max-width: 500px) {
  .items_options_space {
    grid-template-columns: repeat(1, 1fr);
  }
}
.contianer-floorlist_data {
  display: none !important;
}
.data-floor-list-mobile {
  /* width: auto !important;
  display: grid;
  grid-template-columns: repeat(5, 1fr); */
  width: auto !important; 
  text-align: left;
  margin-bottom: 10px;
  display: flex;
  flex-wrap: wrap !important;
}
.data-floor-list-next {
  padding: 0;
}

.add-space-type-space-temp-chart {
  display: inline-block;
  border: 1px solid #dddddd !important;
  margin-right: 10px;
  color: #373737;
  cursor: pointer;
  font-size: 15px !important;
  font-weight: 400 !important;
  line-height: 24px !important;
}

.add-space-type-space-temp-chart.active:hover {
  color: #ffffff !important;
}

.add-space-type-space-temp-chart.active {
  background-color: #005daa !important;
  color: #ffffff !important;
}

.add-space-type-space-temp-chart p {
  font-size: 15px;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  padding-left: 10px;
  padding-right: 10px;
  margin: 0px;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 100%;
  height: 100%;
}