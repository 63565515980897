.mini_chart_container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 10px;
}

.mini_chat_indi {
  /* background-color: #fff; */
}
.mini_chart_container .data_chart_container_porto {
  /* height: 10px; */
  background-color: #fff;
  border: 1px solid #dddddd;
  margin-top: 50px;
  padding: 15px;
  /* width: auto; */

  /* width: 100%; */
}
.mini_chart_container .data_chart_container_porto.dark {
  background-color: #000;
}
/* @media only screen and (max-width: 1300px) {
  .mini_chart_container {
    grid-template-columns: repeat(2, 1fr);
  }
} */
@media only screen and (max-width: 1300px) {
  .mini_chart_container {
    grid-template-columns: 1fr;
  }
}
@media only screen and (max-width: 768px) {
  .mini_chart_container {
    width: 100%;
    grid-gap: 0px;
  }
}

.select_multiple_locations {
  background: #A24400;
  display: flex;
  min-width: 253px;
  text-align: center;
  justify-content: center;
  align-items: center;
  margin: 1rem 1rem 1rem 0rem;
  color: #fff;
  border-radius: 3px;
  padding: 0.3rem 0rem;
  cursor: pointer;
}

/* Adjust for mobile devices (max-width: 767px) */
@media (max-width: 767px) {
  .select_multiple_locations {
    min-width: 50%; /* Make it 100% width for mobile */
  }
}

/* Adjust for tablets like iPads (max-width: 1024px) */
@media (max-width: 1024px) {
  .select_multiple_locations {
    min-width: 50%; /* Set to 80% width for tablets */
  }
}

.tittle_item{
  font-family: Chivo;
  width: 100%;
font-size: 24px;
font-style: normal;
line-height: normal;
}

@media (max-width: 767px) {
  .tittle_item{
  font-size: 16px;
  padding: 0.3rem 1.3rem;
  background: #FFD4AC;
text-align: center;
  font-style: normal;
  line-height: normal;
  }
}

.list_sites {
  display: flex;
  justify-content: space-between;
  margin-top: 1.5rem;
  margin-bottom: -1.5rem;
  font-weight: 700 !important;
  width: 100%;
}