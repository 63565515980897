.notification-blk {
  min-height: 20vh;
  padding-top: 20px;
  border: 1px solid #DDDDDD;
  background-color: #FFFFFF !important;
}

.notification-blk-dark {
  min-height: 20vh;
  padding-top: 20px;
  border: 1px solid #DDDDDD;
  background-color: #0B0B0B !important;
}

.notification-main-blk-dark {
  background-color: #0B0B0B !important;
}

.notification-padding-blk {
  background-color: #E2053F;
  width: 8px;
  height: 33px;
}

.notification-blk-header {
  vertical-align: middle;
  padding-left: 0.5em;
  color: #E2053F;
  font-weight: 600;
  font-size: 28px;
}

.noti-type {
  display: inline-block;
  border: 1px solid #DDDDDD !important;
  margin-right: 0px;
  color: #373737;
  cursor: pointer;
  font-size: 14px !important;
  font-family: "Chivo" !important;
  font-weight: 400 !important;
}

.noti-type.active:hover {
  color: #FFFFFF;
}

.noti-type.active {
  background-color: #E2053F;
  color: #FFFFFF;
}

.noti-type p {
  font-size: 15px;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  padding-left: 10px;
  padding-right: 10px;
  margin: 0px;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 100%;
  height: 100%;
}

.notification-holder {
  padding-top: 1.5em;
  padding-bottom: 1.5em;
}

.read-all, .read-noti {
  cursor: pointer;
  float: right;
}

.noti-list {
  max-height: 65vh;
  padding-top: 1.5em;
  overflow-y: auto;
}

.noti-msg {
  margin-bottom: 0.5em;
  font-size: 15px;
  font-weight: 400;
}

.noti-msg-date {
  font-size: 12px;
  font-weight: 400;
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #E2053F !important;
  border-color: #E2053F !important;
}

.settings-text {
  padding-left: 10px;
}

.settings-text label {
  padding-left: 10px;
}

.settings-text input {
  cursor: pointer;
}

.settings-text input:checked {
  background-color: #E2053F !important;
  border-color: #E2053F !important;
}

.settings-text .cons-level {
  width: 100px;
  display: inline-block;
  margin-left: 10px;
  margin-right: 5px;
}

.settings-text .cons-input {
  padding-left: 10px;
  padding-top: 4px;
  padding-bottom: 4px;
}

.settings-text .cons-focus {
  background-color: #DDDDDD;
  border: 0px !important;
  /* padding: 10px;
  margin-left: 5px;
  margin-right: 5px;
  border-radius: 3px; */
}

.custom-tooltip-dark > .tooltip-inner {
  background-color: #C4C4C4;
  color: #0B0B0B;
  font-family: 'Chivo';
}

.loadmore {
  cursor: pointer;
  font-size: 14px;
}

.hidden {
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s .5s, opacity .5s linear;
}

.form-check-label {
  width: calc(100% - 16px);
  line-height: 2em;
  font-size: 15px;
  font-family: "Chivo" !important;
}

.form-check-input {
  margin-top: 8px !important;
}

.noti-settings-head {
  font-size: 18px !important;
}
