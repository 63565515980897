.header_chart_container {
  background-color: #fff;
  /* width: 98%; */
  height: 320px;
  border: 1px solid #dddddd;
  padding: 25px;
  border-radius: 8px;
}
.header_chart_container.dark {
  background-color: #000;
}
.header_chart_container .title {
  border-left: 10px solid #11c126;
  color: #11c126;
  font-weight: bolder;
  font-size: 28px;
  padding-left: 10px;
  margin-bottom: 25px;
}
.header_chart_container.dark .title {
  color: #fff;
}
.header_chart_container .custom_label {
  display: grid;
  justify-content: center;
  text-align: center;
  grid-template-columns: repeat(12, 1fr);
  margin-left: 48px;
  font-size: 12px;
  max-width: 100%;
  color: blue;
  text-decoration: underline;
  cursor: pointer;
}
.header_chart_container
  .recharts-wrapper
  .recharts-cartesian-grid-horizontal
  line,
.header_chart_container
  .recharts-wrapper
  .recharts-cartesian-grid-horizontal
  line {
  stroke-opacity: 1;
}

.header_chart_container
  .recharts-wrapper
  .recharts-cartesian-grid-vertical
  line,
.header_chart_container
  .recharts-wrapper
  .recharts-cartesian-grid-vertical
  line {
  stroke-opacity: 0;
}
.recharts-layer line {
  stroke-opacity: 0;
}
.header_chart_container .recharts-layer .recharts-bar g g g path {
  fill: red !important;
  background-color: red !important;
}

.header_chart_container
  .recharts-layer.recharts-cartesian-axis.recharts-xAxis.xAxis
  .recharts-layer.recharts-cartesian-axis-tick
  text
  tspan {
  fill: #005daa !important;
  text-decoration: underline;
  font-size: 12px;
  cursor: pointer;
}

.header_chart_container .recharts-layer recharts-bar-rectangle {
  height: 10000px !important;
}
.header_chart_container
  .recharts-layer.recharts-bar:last-child
  .recharts-bar-rectangle
  path {
  transform: translateX(-14px);
}
.billing_calendar_selector {
  width: 100%;
  background-color: white;
  border: 1px solid #dddddd;
  padding: 8px 15px;
}
.billing_calendar {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
@media only screen and (max-width: 768px) {
  /* .header_chart_container {
    min-width: 500px;
  } */
  .header_chart_container .title {
    font-size: 18px;
  }
  .header_chart_container {
    width: 88%;
    margin: auto;
    /* margin-right: 25px; */
    margin-right: 30px !important;
    height: 380px;
  }
  .custom_label {
    font-size: 9px;
  }
  .header_chart_container
    .recharts-layer.recharts-cartesian-axis.recharts-xAxis.xAxis
    .recharts-layer.recharts-cartesian-axis-tick
    text
    tspan {
    font-size: 9px;
  }
}
