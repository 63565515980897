.space-blk .padding-blk, .space-blk-dark .padding-blk {
  background-color: #005DAA;
  width: 8px;
  height: 33px;
}

.inline-blk {
  display: inline-block;
}

.main-blk-dark {
  background-color: #0B0B0B !important;
}

.space-blk {
  min-height: 20vh;
  padding-top: 20px;
  border: 1px solid #DDDDDD;
  background-color: #FFFFFF !important;
}

.space-blk-dark {
  min-height: 20vh;
  padding-top: 20px;
  border: 1px solid #DDDDDD;
  background-color: #0B0B0B !important;
}

.table-blk {
  display: table;
}

.table-cell-blk {
  display: table-cell;
}

.space-blk .blk-header, .space-blk-dark .blk-header {
  vertical-align: middle;
  padding-left: 0.5em;
  color: #005DAA;
  font-weight: 600;
  font-size: 28px;
  font-style: normal;
  line-height: 31px;
}

.dark {
  color: #FFFFFF !important;
}

.settings-icon {
  float: right;
  cursor: pointer;
  padding-top: 0.3em;
}

.space-stats {
  border: 1px solid #DDDDDD;
  height: 150px;
}

.space-bottom {
  background-color: #F9A825;
  text-align: center;
  color: #FFFFFF;
  padding: 5px;
}

.space-bottom i {
  vertical-align: middle;
}

.space-bottom p {
  font-size: 12px;
  line-height: 16px;
  font-weight: 400;
  margin-bottom: 0px;
}

.space-stats .settings-icon {
  float: right;
  padding-right: 10px;
  padding-top: 10px;
}

.space-stats .space-title {
  float: left;
  padding-left: 10px;
  padding-top: 5px;
  width: calc(100% - 32px);
}

.space-title h6 {
  display: inline-block;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  width: calc(100% - 25px);
}

.space-title img {
  width: 12px;
  /* padding-bottom: 3px; */
  vertical-align: baseline;
}

.space-title .bi {
  color: #005DAA;
}

.space-stats-top {
  height: 50px;
}

.space-stats-bottom {
  text-align: center;
}

.space-stats-bottom .space-stats-child {
  display: inline-block;
  float: left;
  width: 50%;
}

.space-stats-bottom .cdn-blk-text {
  color: #005DAA;
  font-size: 24px;
  font-weight: 500;
  font-family: "Chivo";
}

.space-stats-bottom img {
  height: 40px !important;
}

.space-stats-bottom .text-be-float {
  font-size: 12px;
  vertical-align: text-top;
  font-weight: 500;
}

.paging-space-blk {
  display: inline !important;
}

@media (max-width: 767px) {
  .space-blk .padding-blk, .space-blk-dark .padding-blk {
    width: 5px;
    height: 23px;
  }
}
