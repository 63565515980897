.submissions,
.downloads {
  background-color: white;
  min-height: 400px;
  padding: 25px;
}

.submissions.dark,
.downloads.dark {
  background-color: #000;
}
.downloads.dark .search_input {
  background-color: #000;
  color: #fff;
}
.downloads {
  margin-top: 50px;
}
.downloads .active_title {
  background-color: #f2c403;
  color: #fff;
}

.submissions .main_title,
.downloads .main_title {
  border-left: 10px solid #f2c403;
  color: #f2c403;
  font-weight: bold;
  font-size: 28px;
  padding-left: 10px;
  margin-bottom: 25px;
}
.submissions .box .title {
  font-weight: bold;
  color: #373737;
  font-size: 14px;
}
.report.dark .title {
  color: #fff;
}

.submissions .box .count {
  color: #005daa;
  font-size: 20px;
  font-weight: bolder;
  margin-right: 5px;
}
.submissions .box .sign {
  color: #005daa;
  font-size: 14px;
  font-weight: 600;
}
.report.dark .count,
.report.dark .sign {
  color: #fff;
}
.download_table {
  margin-top: 20px;
  /* border: 1px solid #dddddd; */
  /* padding: 25px; */
  background-color: #fff;
}
.download_table.dark {
  background-color: #000;
}
.download_table.dark .table {
  --bs-table-hover-color: #fff;
  color: #fff;
}
.download_table table thead {
  border-top: 1px solid #dddddd !important;
}
.download_table table tr {
  border-bottom: 1px solid #dddddd !important;
  cursor: pointer;
}
.test_report {
  display: grid;
  grid-template-columns: auto auto auto;
}
.sub_header {
  display: flex !important;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.resource_table_container_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.create_resource_button {
  width: fit-content;
  height: 50px;
  border-radius: 8px;

  color: #fff;
  cursor: pointer;
}
.create_resource_button button {
  background-color: #f2c403;
  border: none;
}
.create_resource_button button:hover,
.create_resource_button button:active,
.create_resource_button button:focus {
  background-color: #f2c403;
}
@media only screen and (max-width: 1200px) {
  .downloads .header {
    flex-direction: column;
  }
  .submissions {
    width: 90% !important;

    /* width: 80vw !important; */
  }
  .downloads .search_ {
    width: 100% !important;
    margin-top: 10px !important;
    margin-left: 0px !important;
  }
  .sub_header {
    align-items: flex-start;
    flex-direction: column !important;
  }
  .sub_header div {
    width: 100%;
  }
  .sub_header div button {
    width: 100% !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
  }
  .sub_header div button div:first-child {
    width: auto;
  }
  .sub_header div button div:last-child {
    width: auto;
  }
}
.mobile_download_table_card {
  padding-bottom: 30px;
  border-bottom: 1px solid #dddddd;
}
.right_mobile_ {
  align-items: center;
}
.mobile_title {
  font-family: "Chivo";
  font-weight: bold;
  padding: 20px 0px;
  border-bottom: 3px solid #dddddd;
}
.mt_card {
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
  margin-bottom: 10px;
}
.downloads.light .download_dropdwon .dropdown {
  background-color: #fff;
  border: 1px solid #dddddd;
  border-radius: 8px;
}
.downloads.dark .download_dropdwon .dropdown {
  border: 1px solid #fff;
}
.downloads.dark .download_dropdwon .dropdown {
  background-color: #000;
}
.download_dropdwon .dropdown button {
  width: 100%;
  text-align: left !important;
  border: none;
}
.download_dropdwon .dropdown button:focus {
  box-shadow: none;
  outline: none;
}
.download_dropdwon .btn-check:active + .btn-success,
.btn-check:checked + .btn-success,
.btn-success.active,
.btn-success:active,
.show > .btn-success.dropdown-toggle {
  border: none !important;
}
.download_dropdwon .btn-check:active + .btn-success,
.btn-check:checked + .btn-success,
.btn-success.active,
.btn-success:active,
.show > .btn-success.dropdown-toggle {
  box-shadow: none !important;
}
.downloads.dark .download_dropdwon .dropdown button {
  background-color: #000 !important;
  color: #fff;
}
.downloads.light .download_dropdwon .dropdown button {
  background-color: white !important;
  color: #000;
  border-radius: 8px;
}
.download_dropdwon .dropdown button:after {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
}
.download_dropdwon .show.dropdown div {
  width: 100%;
}
.downloads.dark .download_dropdwon .dropdown button {
  background-color: #000 !important;
  color: #fff;
  border-radius: 8px;
}
.downloads.dark .dropdown_menu {
  background-color: #fff;
}
.downloads.dark .download_dropdwon .show.dropdown div a {
  color: #fff;
}
.downloads.dark .download_dropdwon .show.dropdown .dropdown-menu {
  background-color: #000;
}
.downloads.dark .download_dropdwon .show.dropdown div a:hover {
  color: #000;
}

.resource_pagination_item.active .page-link {
  background-color: #f2c403 !important;
  border: 1px solid #f2c403 !important;
}
.resource_pagination_item .page-link {
  color: #000;
}
.modal_attach_input_file {
  position: relative;
  width: 150px;
  /* padding: 10px; */
  /* border: 1px solid #ced4da; */
  border-radius: 8px;
  text-align: center;
  line-height: 35px;
  height: 35px;
  cursor: pointer;
  background-color: rgb(39, 39, 39);
  color: #fff;
  cursor: pointer;
  margin-top: 12px;
}
.modal_attach_input_file_name {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  -webkit-appearance: none;
  appearance: none;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  margin-bottom: 8px;
  text-decoration: underline;
}
.modal_attach_input_file input {
  background-color: blue;
  width: 150px;
  height: 35px;
  position: absolute;
  left: 0px;
  opacity: 0;
  cursor: pointer;
}
.resource_table_spinnser_container .spinner-border {
  color: #c4c4c4 !important;
}
.modalForm.dark .modal-content .form-select {
  background-color: rgb(37, 37, 37);
  color: #fff;
}
@media only screen and (min-width: 800px) {
  .downloads.container {
    max-width: 91% !important;
    margin-left: auto;
    margin-right: 25px;
  }
}
@media only screen and (max-width: 768px) {
  .downloads {
    margin: auto;
    border-radius: 8px;
  }
  .mobile_sub,
  .mobile_down {
    width: 100%;
  }
  .downloads .main_title {
    font-size: 18px;
  }
  .submissions,
  .downloads {
    width: 88% !important;
    /* width: 90vw !important; */
    margin-right: 30px !important;
    /* margin: auto !important; */
  }
  .test_report {
    display: grid;
    grid-template-columns: auto auto;
    place-self: center;
    place-items: center;
  }
  .right_mobile_ {
    display: flex;
    align-items: flex-start !important;
    justify-content: flex-start !important;
    flex-direction: column;
  }
  .right_mobile_ button {
    margin-top: 20px;
    /* width: 65vw !important; */
    width: 100%;
    justify-content: center !important;
  }
  .resource_table_container_header {
    flex-direction: column;
  }
}
@media only screen and (min-width: 968px) {
  .downloads.container {
    max-width: 100% !important;
    margin-left: auto;
    margin-right: 25px;
  }
}
