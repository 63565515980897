.blank-blk {
  /* min-height: 30vh; */
  border: 2px dashed #DDDDDD;
}

.blank-blk img {
  width: 60px;
  margin: auto;
  cursor: pointer;
}
