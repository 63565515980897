.background {
  background-image: url("../../assets/images/Kaer Facade.jpeg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  width: 100%;
  height: 100vh !important;
  font-family: 'Graphik', sans-serif !important;
}

.background-mobile {
  display: table-cell !important;
  vertical-align: bottom;
}

.background-mobile .login-background {
  margin-bottom: calc((100vh - 526px)/2) !important;
}

.background-desktop .login-background {
  margin-top: calc((100vh - 526px)/2) !important;
}

.background-mobile .background-text {
  padding-bottom: 0px;
}

.background-desktop .background-text {
  position: absolute;
  bottom: 0;
}

.login-background {
  background: #005daa;
}

.login-box {
  background-color: #fff !important;
  margin-left: 0px !important;
  margin-top: 10px !important;
}

.login-box-dark {
  margin-left: 0px !important;
  margin-top: 10px !important;
}

.login-company-logo {
  width: 220px !important;
  height: 125px !important;
  margin: auto;
  margin-top: 15px !important;
}

.forgot-password {
  color: #373737;
  /* text-align: center; */
  font-family: "Graphik" !important;
  font-size: 12px;
}

.forgot-password-dark {
  color: #FFFFFF;
  /* text-align: center; */
  font-family: "Graphik" !important;
  font-size: 12px;
}

.forgot-password-p {
  text-align: center;
}

.login-text-color {
  color: #373737;
  cursor: pointer;
  font-family: "Graphik" !important;
  font-size: 12px;
  line-height: 15.6px;
}

.login-text-color-dark {
  color: #FFFFFF;
  cursor: pointer;
  font-family: "Graphik" !important;
  font-size: 12px;
  line-height: 15.6px;
}

.login-icon-color {
  margin-right: 5px;
  color: #00a6ef;
  vertical-align: text-top;
}

.login-button-color {
  background-color: #005DAA !important;
  color: #FFFFFF;
  width: 100%;
  height: 44px;
  font-family: "Graphik" !important;
  font-size: 15px !important;
  font-weight: 300 !important;
}

[data-theme='dark'] {
  background: #0B0B0B !important;
  color: #fff;
}

.login-box-dark .rememberMe {
  color: #ffffff !important;
}

.login-box .form-floating label, .login-box .form-check label, .login-box-dark .form-floating, .login-box-dark .form-floating {
  color: #373737 !important;
  font-weight: 300;
  font-size: 15px;
  font-family: "Graphik" !important;
}

.form-check-input:checked {
  background-color: #005DAA !important;
  border-color: #005DAA !important;
}

.background-text {
  /* position: absolute;
  bottom: 0;
  left: 0; */
  padding-bottom: 10px;
  color: #FFFFFF;
}

.login-box .form-check-label {
  font-family: "Graphik" !important;
  font-size: 12px;
  font-weight: 300;
  color: #373737;
  line-height: 2.6em;
}

.login-sub-blk .floating-password {
  width: calc(100% - 42px) !important;
}

.login-sub-blk .password, .login-sub-blk .confirm_password {
  border-right: 0px;
  border-radius: 0;
}

.login-sub-blk .input-group-text {
  border-left: 0px;
  background-color: white;
}

.see-password {
  cursor: pointer;
}

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active{
    -webkit-background-clip: text;
    -webkit-text-fill-color: #000;
    transition: background-color 5000s ease-in-out 0s;
    box-shadow: inset 0 0 20px 20px #fff;
}
