.force-reset-blk {
  background-image: url("../../assets/images/Kaer Facade.jpeg");
  background-repeat: no-repeat;
  background-position: right;
  background-size: cover;
  height: 100vh;
  display:flex;
  align-items:center;
  justify-content:center;
  text-align: center;
  margin: 0 auto;
  border: 1px solid #DDDDDD;
}

.force-reset-blk-dark {
  background-image: url("../../assets/images/Kaer Facade.jpeg");
  background-repeat: no-repeat;
  background-position: right;
  background-size: cover;
  height: 100vh;
  display:flex;
  align-items:center;
  justify-content:center;
  text-align: center;
  margin: 0 auto;
  border: 1px solid #DDDDDD;
}

.reset-button-color {
  background-color: #005DAA !important;
  color: #FFFFFF;
  width: 100%;
  height: 44px;
  font-family: "Graphik" !important;
  font-size: 15px !important;
  font-weight: 300 !important;
}

.resetHeader {
  text-align: left;
  font-size: 18px;
}

.resetTitle {
  color: #005DAA !important;
}

.force-reset-sub-blk .form-floating {
  width: calc(100% - 43px) !important;
}

.force-reset-sub-blk .password, .force-reset-sub-blk .confirm_password {
  border-right: 0px;
  border-radius: 0;
}

.force-reset-sub-blk .input-group-text {
  border-left: 0px;
  background-color: white;
}

.see-password {
  cursor: pointer;
}
