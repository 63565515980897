.live-blk .padding-blk, .live-blk-dark .padding-blk {
  background-color: #005DAA;
  width: 8px;
  height: 33px;
}

.inline-blk {
  display: inline-block;
}

.main-blk-dark {
  background-color: #242424 !important;
}

.live-blk {
  min-height: 250px;
  padding-top: 20px;
  border: 1px solid #DDDDDD;
  background-color: #FFFFFF !important;
}

.live-blk-dark {
  min-height: 250px;
  padding-top: 20px;
  border: 1px solid #DDDDDD;
  background-color: #242424 !important;
}

.live-blk .main-blk, .live-blk-dark .main-blk {
  cursor: grab;
}

.live-blk .table-blk, .live-blk-dark .table-blk {
  display: table;
  padding-left: 10px;
}

.table-cell-blk {
  display: table-cell;
}

.blk-header {
  vertical-align: middle;
  padding-left: 0.5em;
  color: #005DAA;
  font-weight: 600;
}

.dark {
  color: #FFFFFF !important;
}

.live-blk .settings-icon, .live-blk-dark .settings-icon {
  float: right;
  cursor: pointer;
  padding-top: 0.3em;
  padding-right: 10px;
}

.liveStatsStarBlk {
  text-align: center;
  padding-top: 1em;
  padding-bottom: 1em;
}

.cdn-blk {
  display: inline-block;
  /* text-align: center; */
}

.live-blk .cdn-blk-parent, .live-blk-dark .cdn-blk-parent {
  /* background-color: #F8F8F8; */
  margin: 1.5em !important;
  padding: 1em;
}

.live-blk .cdn-blk-parent-dark, .live-blk-dark .cdn-blk-parent-dark {
  /* background-color: #424242; */
  margin: 1.5em !important;
  padding: 1em;
}

.live-blk .cdn-blk-text, .live-blk-dark .cdn-blk-text {
  color: #005DAA;
  font-size: 24px;
  font-weight: bold;
  font-family: "Chivo" !important;
  margin-top: -0.7em;
}

.cdn-blk-text-cooling {
  margin-top: -0.5em;
}

.live-blk .liveStatsStarImg, .live-blk-dark .liveStatsStarImg {
  height: 40px !important;
}

.cdn-blk-title {
  height: 60px;
  font-weight: bold;
}

.cdn-blk-title h5 {
  font-family: "Chivo" !important;
  font-weight: bold;
  font-size: 18px;
}

.cdn-blk-title-left-div, .cdn-blk-title-right-div {
  display: inline-block;
}

.cdn-blk-title-left-div {
  width: 25% !important;
  float: left !important;
  padding-left: 1em !important;
}

.live-blk .cdn-blk-title-right-div, .live-blk-dark .cdn-blk-title-right-div {
  width: 100% !important;
  padding-top: 2em;
}

.cdn-blk-title-left-div h5 {
  font-weight: bold;
  font-family: "Chivo" !important;
}

.live-blk .cdn-blk-title-right, .live-blk-dark .cdn-blk-title-right {
  float: left;
  font-weight: bold;
  padding-left: 1em;
  display: inline-block;
  font-family: "Chivo" !important;
  text-overflow: ellipsis;
  overflow: hidden;
  font-size: 18px;
}

#liveStatsDrop {
  float: left;
  display: inline-block;
  padding-right: 5px;
  padding-left: 5px;
  color: #373737;
}

.right-img {
  height: 50px;
}

.custom-tooltip-dark > .tooltip-inner {
  background-color: #C4C4C4;
  color: #0B0B0B;
  font-family: 'Chivo';
}

.live-blk .text-be-float, .live-blk-dark .text-be-float {
  font-size: 12px;
  vertical-align: text-top;
  font-weight: 700;
  font-family: "Chivo";
}

.text-be-float sup {
  font-size: 12px;
}

.text-be-float-div {
  display: inline-block;
}

.live-blk .text-be-float {
  color: #005DAA;
}

.live-blk-dark .text-be-float {
  color: #FFFFFF;
}


.sub-header1 {
  padding-top: 1em;
}

.sub-header1 h5 {
  padding-left: 2.3em;
  font-weight: bold;
  font-family: "Chivo" !important;
}

.live-value {
  color: #005DAA;
  font-size: 24px;
  font-weight: 700;
  font-family: "Chivo";
  display: inline-block;
}

@media (max-width: 767.98px) {
  .live-blk .cdn-blk-title-right, .live-blk-dark .cdn-blk-title-right {
    max-width: calc(100% - 24px);
    font-size: 15px !important;
  }
}
