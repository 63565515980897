.dark {
  color: #FFFFFF !important;
}

.dprofiles-blk {
  min-height: 500px;
  border: 1px solid #DDDDDD;
  background-color: #FFFFFF !important;
}

.dprofiles-blk-dark {
  min-height: 500px;
  border: 1px solid #DDDDDD;
  background-color: #242424 !important;
}

.dprofile-main-blk {
  cursor: grab;
}

.dprofile-main-blk-dark {
  background-color: #0B0B0B !important;
  cursor: grab;
}

.dprofile-sub-blk {
  padding-top: 20px;
}

.dprofile-blk {
  display: table;
}

.dprofile-cell-blk {
  display: table-cell;
}

.dprofile-padding-blk {
  background-color: #005DAA;
  width: 8px;
  height: 33px;
}

.dprofile-blk-header {
  vertical-align: middle;
  padding-left: 0.5em;
  color: #005DAA;
  font-weight: 600;
}

.dprofiles-blk .settings-icon, .dprofiles-blk-dark .settings-icon {
  float: right;
  cursor: pointer;
  padding-top: 0.3em;
  padding-right: 10px;
}

.dprofile-dprofile {
  height: 400px;
  padding-top: 1em;
  padding-left: 1em !important;
  padding-right: 1em !important;
}

.dprofile-dprofile p, .dprofile-dprofile span {
  font-size: 12px;
}

.dprofile-blk-title {
  font-weight: bold;
  float: left;
  display: inline-block;
  padding-top: 1.5em;
  max-width: 90%;
  margin-bottom: 0px;
  cursor: pointer;
}

.dprofile-info {
  color: #005DAA;
}

.read-dprofile {
  cursor: pointer;
}

.dprofile-dprofile .company-info-blk {
  display: inline-block;
  min-width: 40px;
  height: 80px;
  vertical-align: text-top;
}

.dprofile-dprofile .company-info-blk img {
  vertical-align: top;
}

.dprofile-dprofile .company-info-blk-value {
  width: calc(100% - 40px) !important;
  display: inline-block;
  height: 73px;
  font-family: "Chivo" !important;
}
