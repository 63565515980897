.mini_chart_container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 10px;
}

.mini_chat_indi {
  /* background-color: #fff; */
}
.mini_chart_container .data_chart_container {
  /* height: 10px; */
  background-color: #fff;
  border: 1px solid #dddddd;
  margin-top: 50px;
  padding: 15px;
  /* width: auto; */

  /* width: 100%; */
}
.mini_chart_container .data_chart_container.dark {
  background-color: #000;
}
/* @media only screen and (max-width: 1300px) {
  .mini_chart_container {
    grid-template-columns: repeat(2, 1fr);
  }
} */
@media only screen and (max-width: 1300px) {
  .mini_chart_container {
    grid-template-columns: 1fr;
  }
}
@media only screen and (max-width: 768px) {
  .mini_chart_container {
    width: 100%;
    grid-gap: 0px;
  }
}
