.red-background {
  background-color: #e2053f;
}

.blue-background {
  background-color: #005daa;
}

.light-blue-background {
  background-color: #00a6ef;
}

.light-orange-background {
  background-color: #F97E25;
}


.navigation-bar-column {
  width: 150px !important;
  display: inline-block !important;
  background-color: #ffffff;
  height: 100vh;
  overflow-y: auto;
}

.navigation-bar-column-dark {
  width: 150px !important;
  border-right: 1px solid #424242;
  display: inline-block !important;
  background-color: #0b0b0b;
  height: 100vh;
  overflow-y: auto;
}

.content-left {
  width: 100%;
  display: inline-block !important;
  float: right;
  padding-left: 0px !important;
  padding-right: 0px !important;
  height: 100vh;
  overflow-y: auto;
  overflow-x: hidden;
}

.navigation-bar {
  text-align: center;
}

.navigation-company-logo {
  max-width: 93px !important;
  margin: auto;
  margin-top: 5px !important;
}

.navigation-company-logo-mobile {
  max-width: 70px !important;
  margin: auto;
  padding-left: 10px;
}

.navigation-icon {
  margin: auto;
  width: 28px;
  cursor: pointer;
  color: #ffffff;
  stroke: #ffffff;
  fill: none;
}

.navigation-icon-dark {
  width: 100%;
  margin: auto;
  stroke: #373737;
}

.navigation-icon-no-color {
  font-size: 15px;
  margin: auto;
  stroke: #373737;
}

.navigation-icon-no-color-dark {
  font-size: 15px;
  margin: auto;
  stroke: #ffffff;
}

.navigation-fill-icon {
  font-size: 15px;
  margin: auto;
  color: #ffffff;
  stroke: #ffffff;
  fill: #ffffff;
}

.navigation-fill-icon-dark {
  font-size: 15px;
  margin: auto;
  stroke: #424242;
  fill: #424242;
}

.navigation-fill-icon-no-color {
  font-size: 15px;
  margin: auto;
  stroke: #424242;
  fill: #424242;
}

.navigation-fill-icon-no-color-dark {
  font-size: 15px;
  margin: auto;
  stroke: #ffffff;
  fill: #ffffff;
}

.navigation-circle {
  display: flex;
  border-radius: 50%;
  width: 45px;
  height: 45px;
  background-color: #005daa;
  margin: auto;
  cursor: pointer;
}

.navigation-circle-dark {
  display: flex;
  border-radius: 50%;
  width: 45px;
  height: 45px;
  background-color: #ffffff;
  margin: auto;
  cursor: pointer;
}

.navigation-circle-no-color,
.navigation-circle-no-color-dark {
  display: flex;
  border-radius: 50%;
  width: 45px;
  height: 45px;
  margin: auto;
  cursor: pointer;
}

.navigation-link {
  font-size: 18px;
  font-family: "Chivo" !important;
  color: #373737 !important;
}

.navigation-link-dark {
  font-size: 18px;
  font-family: Chivo;
  color: #ffffff !important;
}

.navigation-link.selected {
  color: #005DAA !important;
}

.navigation-link-dark.selected {
  color: #ffffff !important;
}

.navigation-right {
  height: 90px;
}

.navigation-right-hide {
  width: 100%;
  height: 150px;
  z-index: -1;
}

.navigation-right-hide-dark {
  background-color: #000000;
  width: 100%;
  height: 150px;
  z-index: -1;
}

.navigation-right.bg_change_data_view,
.navigation-right-hide.bg_change_data_view {
  background-color: #00a6ef;

  /* min-width: 600px;  */
}


.navigation-right.bg_change_portfolio_view,
.navigation-right-hide.bg_change_portfolio_view {
  background-color:  #F97E25;

}

.navigation-right.bg_change_billing_view,
.navigation-right-hide.bg_change_billing_view {
  background-color: #11c126;
}

.navigation-right.bg_change_resources_view,
.navigation-right-hide.bg_change_resources_view {
  background-color: #f2c403;
}
.bi-person-circle {
  color: #ffffff;
}

.acccount-name,
.page-name,
.page-name-mobile,
#dropdownMenuLink {
  color: #ffffff;
}

.acccount-name {
  font-family: "Chivo" !important;
  font-size: 15px;
  text-align: center;
  min-width: calc(100% - 55px);
  line-height: 0.7em;
}

#dropdownMenuLink {
  margin-left: 0.5em;
}

.page-name {
  text-transform: capitalize;
  font-weight: 600;
  font-size: 36px;
}

.page-name-mobile {
  text-transform: capitalize;
  font-weight: 600;
  font-size: 24px;
  padding-top: 15px;
  margin-left: -15px;
}

.fixed-children {
  margin-top: -150px !important;
}

[data-theme="dark"] {
  background: #0b0b0b !important;
}

.avatar-icon {
  margin-right: 0.25em;
  cursor: pointer;
  width: 30px;
  height: 30px !important;
  border-radius: 50%;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Graphik" !important;
}

p {
  font-family: "Chivo" !important;
}

.copyrights {
  /* position: fixed; */
  padding-right: 40px;
  padding-top: 20px;
  float: right;
  right: 0;
  bottom: 0;
  font-size: 15px !important;
}

.small-modal .modal-dialog {
  max-width: 400px !important;
}

.refresh-save {
  background-color: #005daa !important;
  color: #ffffff !important;
}

.small-modal .dark-blk {
  border-top: 0px;
}

.small-modal .modal-content {
  border: 1px solid #424242;
}

.navigation-mobile-column,
.navigation-mobile-column-dark {
  position: absolute !important;
  width: 100% !important;
  background-color: #ffffff;
  z-index: 1000;
}

.navigation-mobile-column .navbar-light .navbar-toggler {
  border-color: #ffffff;
  color: #ffffff;
}

.navigation-mobile-column .navbar-collapse {
  background-color: #ffffff;
  padding-left: 15px;
  z-index: 10000;
  border: 2px solid #dddddd;
}

.navigation-mobile-column-dark {
  background-color: #000000;
  border-bottom: 1px solid #dddddd;
}

.navigation-mobile-column-dark .navbar-collapse {
  color: #ffffff;
  padding-left: 15px;
  z-index: 10000;
  border: 1px solid #ffffff;
  background-color: #000000;
}

#responsive-navbar-nav {
  margin: 0.001px;
  border-radius: 3px;
}

.navigation-mobile-column .nav-link:hover {
  color: #005daa !important;
}

.navigation-mobile-column-dark .nav-link:hover {
  color: #005daa !important;
}

.navigation-mobile-column .nav-item a {
  padding-left: 0px;
}

.navigation-mobile-column-dark .nav-item a {
  padding-left: 0px;
  color: #ffffff !important;
}

.navigation-mobile-column .navbar-toggler-icon {
  background-image: url(../assets/images/navigation/nav-toggle.svg) !important;
  width: 1.3em;
}

.navigation-mobile-column-dark .navbar-toggler-icon {
  background-image: url(../assets/images/navigation/nav-toggle-alt.svg) !important;
  width: 1.3em;
}

.navigation-mobile-column .navbar,
.navigation-mobile-column-dark .navbar {
  padding-top: 5px;
  padding-bottom: 5px;
}

@media only screen and (max-width: 768px) {
  .navigation-right.bg_change_data_view,
  .navigation-right-hide.bg_change_data_view {
    background-color: #00a6ef;
    width: 100vw;
  }
  .navigation-right-hide.bg_change_data_view {
    height: 250px;
  }

  .navigation-right.bg_change_portfolio_view,
  .navigation-right-hide.bg_change_portfolio_view {
    background-color: #F97E25;
    width: 100vw;
  }
  .navigation-right-hide.bg_change_portfolio_view {
    height: 250px;
  }
  
  /* Adjust height for mobile devices (max-width: 767px) */
  @media (max-width: 767px) {
    .navigation-right-hide.bg_change_portfolio_view {
      height: 150px;
    }
  }
  
  /* Adjust height for tablets (max-width: 1024px) */
  @media (max-width: 1024px) {
    .navigation-right-hide.bg_change_portfolio_view {
      height: 180px;
    }
  }
  .navigation-right.bg_change_billing_view,
  .navigation-right-hide.bg_change_billing_view {
    width: 100vw;
  }
  .navigation-right.bg_change_resources_view,
  .navigation-right-hide.bg_change_resources_view {
    width: 100vw;
  }
  .content-left {
    padding-left: 10px !important;
  }
}

.go-mobile .table-cell-blk, .go-mobile .noti-cell-blk, .go-mobile .contact-blk-header,
.go-mobile .dbilling-blk-header, .go-mobile .dprofile-blk-header {
  font-size: 18px !important;
  line-height: 24px !important;
}

.go-mobile .padding-blk {
  width: 5px;
  height: 16px
}

.navi-row {
  display: inline-block;
  float: right;
  width: calc(100vw - 150px);
}

.feedbackBtn {
  cursor: pointer !important;
}

.rdoSite {
  height: 24px;
  vertical-align: sub;
}

.rdoSiteLabel {
  vertical-align: text-bottom;
  cursor: pointer;
}

@media (max-width: 767.98px) {
  .content-left {
    width: 100% !important;
    display: inline-block;
    float: right;
    padding-top: 56px;
  }
  .navigation-right-hide {
    width: 100%;
    height: 150px;
    z-index: -1;
  }
  .navi-row {
    width: 100vw !important;
  }
}

@media (min-width: 768px) {
  .content-left {
    width: 100%;
    border-left: 1px solid #dddddd;
  }
}
