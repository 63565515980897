.dark {
  color: #FFFFFF !important;
}

.resos-blk {
  max-height: 450px;
  border: 1px solid #DDDDDD;
  background-color: #FFFFFF !important;
}

.resos-blk-dark {
  max-height: 450px;
  border: 1px solid #DDDDDD;
  background-color: #242424 !important;
}

.reso-main-blk {
  cursor: grab;
}

.reso-main-blk-dark {
  background-color: #242424 !important;
  cursor: grab;
}

.reso-sub-blk {
  padding-top: 20px;
}

.reso-blk {
  display: table;
}

.reso-cell-blk {
  display: table-cell;
}

.reso-padding-blk {
  background-color: #F2C403;
  width: 8px;
  height: 33px;
}

.reso-blk-header {
  vertical-align: middle;
  padding-left: 0.5em;
  color: #F2C403;
  font-weight: 600;
}

.resos-blk .settings-icon, .resos-blk-dark .settings-icon {
  float: right;
  cursor: pointer;
  padding-top: 0.5em;
  padding-right: 10px;
}

.resos-blk-dark .reso-info {
  color: #FFFFFF;
}

.reso-noti {
  max-height: 340px;
  padding-top: 5px;
  display: table !important;
}

.reso-blk-title {
  font-weight: bold;
  float: left;
  display: inline-block;
  max-width: 90%;
  margin-bottom: 0px;
  cursor: pointer;
  font-size: 15px;
  color: #F2C403;
  padding-top: 2em;
  padding-bottom: 1em;
}

.reso-info {
  color: #F2C403;
  min-height: 40px;
}

.reso-info p {
  font-size: 14px;
}

.read-noti {
  cursor: pointer;
  font-size: 12px;
}

.resos-blk-dark .reso-blk-title {
  color: #F2C403 !important;
}

.resos-bllk .reso-blk-titlle {
  color: #F2C403 !important;
}

.reso-cdn-blk-title-right {
  font-size: 18px;
  padding-left: 12px;
  display: inline-block;
}

#resoDrop {
  display: inline-block;
  padding-right: 5px;
  padding-left: 5px;
  color: #373737;
}

.reso-blk-bottom {
  font-weight: bold;
  float: left;
  display: inline-block;
  max-width: 90%;
  margin-bottom: 0px;
  cursor: pointer;
  font-size: 15px;
  color: #F2C403;
  /* padding-top: 2em; */
  padding-bottom: 1em;
}
