.data_chart_container .custom_label {
  display: grid;
  justify-content: center;
  text-align: center;
  grid-template-columns: repeat(12, 1fr);
  margin-left: 48px;
  font-size: 12px;
  max-width: 100%;
  /* color: blue; */
  text-decoration: underline;
  cursor: pointer;
}
.data_chart_container
  .recharts-wrapper
  .recharts-cartesian-grid-horizontal
  line,
.data_chart_container
  .recharts-wrapper
  .recharts-cartesian-grid-horizontal
  line {
  stroke-opacity: 1;
}

.data_chart_container .recharts-wrapper .recharts-cartesian-grid-vertical line,
.data_chart_container .recharts-wrapper .recharts-cartesian-grid-vertical line {
  stroke-opacity: 0;
}
.recharts-layer line {
  stroke-opacity: 0;
}
.data_chart_container .recharts-layer .recharts-bar g g g path {
  fill: red !important;
  background-color: red !important;
}

.data_chart_container
  .recharts-layer.recharts-cartesian-axis.recharts-xAxis.xAxis
  .recharts-layer.recharts-cartesian-axis-tick
  text
  tspan {
  font-size: 12px;
}

.data_chart_container .recharts-layer recharts-bar-rectangle {
  height: 10000px !important;
}
.data_chart_container
  .recharts-layer.recharts-bar:last-child
  .recharts-bar-rectangle
  path {
  /* transform: translateX(-14px); */
}

.billing_calendar_selector {
  width: 100%;
  background-color: white;
  border: 1px solid #dddddd;
  padding: 8px 15px;
}
.billing_calendar {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* .data_chart_container.over_12_bars
  .recharts-layer.recharts-bar:last-child
  .recharts-bar-rectangle
  path {
  transform: translateX(-11px);
} */

@media only screen and (max-width: 768px) {
  /* .data_chart_container {
      min-width: 500px;
    } */

  .data_chart_container {
    width: 90%;
    margin: 0px auto;
    margin-right: 25px;
    height: 380px;
  }
  .custom_label {
    font-size: 9px;
  }
  .data_chart_container
    .recharts-layer.recharts-cartesian-axis.recharts-xAxis.xAxis
    .recharts-layer.recharts-cartesian-axis-tick
    text
    tspan {
    font-size: 9px;
  }
}
