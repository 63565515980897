.dark {
  color: #FFFFFF !important;
}

.dbillings-blk {
  min-height: 490px;
  border: 1px solid #DDDDDD;
  background-color: #FFFFFF !important;
}

.dbillings-blk-dark {
  min-height: 490px;
  border: 1px solid #DDDDDD;
  background-color: #242424 !important;
}

.dbilling-main-blk {
  cursor: grab;
}

.dbilling-main-blk-dark {
  background-color: #0B0B0B !important;
  cursor: grab;
}

.dbilling-sub-blk {
  padding-top: 20px;
}

.dbilling-blk {
  display: table;
}

.dbilling-cell-blk {
  display: table-cell;
}

.dbilling-padding-blk {
  background-color: #11C126;
  width: 8px;
  height: 33px;
}

.dbilling-blk-header {
  vertical-align: middle;
  padding-left: 0.5em;
  color: #11C126;
  font-size: 28px;
  font-style: normal;
  line-height: 31px;
}

.dbillings-blk .settings-icon, .dbillings-blk-dark .settings-icon {
  float: right;
  cursor: pointer;
  padding-top: 0.5em;
  padding-right: 10px;
}

.dbilling-dbilling {
  height: 400px;
  padding-bottom: 1.5em;
  padding-left: 0em !important;
}

.dbilling-blk-title {
  font-weight: bold;
  float: left;
  display: inline-block;
  padding-top: 1.5em;
  max-width: 90%;
  margin-bottom: 0px;
  cursor: pointer;
}

.dbilling-dbilling .custom_label {
  display: grid;
  text-align: center;
  grid-template-columns: repeat(12, 8.3%);
  font-size: 9px;
  width: 100%;
  margin-left: 0px;
  color: #11C126;
  text-decoration: none;
  font-weight: 600;
  padding-left: 60px;
}

.custom_label div {
  word-spacing: 0.5em;
}

/* @media (max-width: 576px) {
  .dbilling-dbilling .custom_label {
    padding-left: 20% !important;
  }
}

@media (min-width: 577px) and (max-width: 768px) {
  .dbilling-dbilling .custom_label {
    padding-left: 10% !important;
  }
} */
