.space-blk .blank-blk,
.space-blk-dark .blank-blk {
  height: 150px !important;
}

.add-space-type-live {
  display: inline-block;
  border: 1px solid #dddddd !important;
  margin-right: 10px;
  color: #373737;
  cursor: pointer;
  font-size: 15px !important;
  font-weight: 400 !important;
  line-height: 24px !important;
}

.add-space-type-live.active:hover {
  color: #ffffff !important;
}

.add-space-type-live.active {
  background-color: #005daa !important;
  color: #ffffff !important;
}

.add-space-type-live p {
  font-size: 15px;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  padding-left: 10px;
  padding-right: 10px;
  margin: 0px;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 100%;
  height: 100%;
}

.space-type-chooser {
  background-color: #f8f8f8;
  padding: 10px;
  height: 100%;
}

.space-type-chooser-accordion .accordion-item,
.space-type-chooser-accordion .accordion-button {
  background-color: #f8f8f8 !important;
}

.space-type-chooser-accordion .accordion-button:not(.collapsed) {
  color: #373737 !important;
}

.space-type-chooser hr {
  margin-top: 0.5em;
  margin-bottom: 0.5em;
}

.middle-padding-space-blk {
  /* width: 1px;
  height: 100px;
  border-left: 1px solid #DDDDDD;
  display: inline; */
  vertical-align: super;
  border: 1px solid #dddddd;
  margin-left: 20px;
  margin-right: 20px;
}

.paging-space-blk {
  display: inline !important;
  word-break: break-all;
  width: calc(100% - 115px);
  vertical-align: middle;
}

.paging-space-page {
  padding: 5px;
  cursor: pointer;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.paging-space-page.active {
  color: #005daa;
  font-weight: bold;
}

.dark {
  color: #ffffff !important;
}

.dark-blk {
  background-color: #0b0b0b !important;
}

.dark.dark-blk .btn-close {
  background: url(../../assets/images/live/close-dark.svg);
  background-repeat: no-repeat;
  background-position: center;
}

.space-floor-blk {
  border: 1px solid #c4c4c4;
  padding: 0px;
  margin-top: 5px;
  margin-bottom: 5px;
  cursor: pointer;
}

.space-floor-blk img {
  padding-left: 10px;
}

.space-blk-name {
  padding-left: 10px;
  padding-right: 10px;
  font-size: 15px;
  font-weight: 400;
  line-height: 15px;
}

.modal-dialog {
  max-width: 900px !important;
}

.space-floor-blk.active {
  background-color: #005daa;
  color: #ffffff;
}

.space-floor-blk.disabled {
  background-color: #c4c4c4;
  color: #ffffff;
}

.space-type-info {
  cursor: pointer;
  font-size: 14px;
  line-height: 18px;
  font-family: "Chivo" !important;
  font-weight: 400;
}

.space-type-info.active {
  color: #005daa;
  font-weight: bold;
}

.space-blk-level {
  font-size: 14px;
  font-weight: 400;
  font-family: "Chivo" !important;
  line-height: 18px;
}

.paging-space-blk-mobile {
  width: 100%;
  text-align: left;
  margin-bottom: 10px;
}

.paging-space-page-mobile-parent {
  width: 40px;
  display: inline-block;
}

.paging-space-page-mobile {
  padding: 15px;
  cursor: pointer;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.paging-space-page-mobile.active {
  color: #005daa;
}

.spacer {
  padding-left: 4px !important;
  padding-right: 4px !important;
}

.spacer .space-floor-blk {
  height: 90px;
}

.space-blk-dark .space-stats {
  background-color: #424242;
}

@media (max-width: 767px) {
  .modal-title-blk span {
    font-size: 18px !important;
  }
  .modal-padding-blk {
    width: 5px !important;
    height: 23px !important;
  }
  .add-space-type {
    font-size: 15px !important;
  }
  .space-blk-name {
    font-size: 12px !important;
  }
  .spacer .space-floor-blk {
    height: 80px !important;
  }
}

.space-stats-wrapper {
  cursor: grab;
}

.space-stats-wrapper.drag-over {
  border: 2px dashed #000;
  background-color: rgba(0, 0, 0, 0.1);
  transition: all 250ms;
}

.space-stats-wrapper.drag-source {
  opacity: 0.4;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  background-color: rgba(145, 200, 248, 0.75);
  transform: scale(0.9);
  transition: all 250ms;
}
