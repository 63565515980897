.profile-blk {
  min-height: 60vh;
  padding-top: 20px;
  border: 1px solid #dddddd;
  background-color: #ffffff !important;
}

.profile-blk-dark {
  min-height: 60vh;
  padding-top: 20px;
  border: 1px solid #dddddd;
  background-color: #0b0b0b !important;
}

.profile-main-blk-dark {
  background-color: #0b0b0b !important;
}

.profile-padding-blk {
  background-color: #005daa;
  width: 8px;
  height: 33px;
}

.profile-blk-header {
  vertical-align: middle;
  padding-left: 0.5em;
  color: #005daa;
  font-weight: 600;
}

.profile-label {
  min-width: 100px;
  font-size: 15px;
}

.profile-label-head {
  font-size: 18px;
}

.profile-input {
  width: calc(100% - 110px) !important;
  padding-left: 5px;
}

.profile-input input {
  width: calc(100% - 110px) !important;
  padding-left: 5px;
}

.profile-value {
  font-weight: bold;
  font-size: 15px;
  padding-left: 5px;
}

.profile-details span,
.profile-details label,
.profile-details input {
  font-family: "Chivo" !important;
}

.change-password {
  background-color: #005daa !important;
  font-family: "Chivo" !important;
  font-size: 14px !important;
}

.edit-profile-btn,
.edit-company-btn {
  background-color: #373737 !important;
  margin-left: 10px;
  font-size: 14px !important;
}

.save-profile-btn,
.save-company-btn {
  background-color: #005daa !important;
  margin-left: 10px;
  font-size: 14px !important;
}

.company-img {
  width: 100% !important;
}

.company-info-blk {
  display: inline-block;
  min-width: 40px;
  height: 75px;
  vertical-align: text-top;
}

.company-info-blk img {
  vertical-align: text-top;
}

.profile-blk .company-info-logo,
.profile-blk-dark .company-info-logo {
  padding-bottom: 50%;
}

.profile-blk .company-info-logo-address,
.profile-blk-dark .company-info-logo-address {
  padding-bottom: 60px;
}

.profile-blk .company-info-blk-value,
.profile-blk-dark .company-info-blk-value {
  width: calc(100% - 40px) !important;
  display: inline-block;
  height: 73px;
  font-size: 12px;
}

.profile-blk .profile-details,
.profile-blk-dark .profile-details {
  padding-left: 1em;
}

.profile-blk .input_error_cls,
.profile-blk-dark .input_error_cls {
  border: 1px solid #e72d35 !important;
  color: #e72d35;
  background: #fff0f0 !important;
}

.profile-blk .input_error_cls input,
.profile-blk-dark .input_error_cls input {
  background: #fff0f0 !important;
}

.profile-img {
  height: 80px !important;
  width: 80px !important;
  border-radius: 50%;
}

.edit-profile-img {
  margin-left: -20px;
  margin-top: 60px;
  cursor: pointer;
}

.edit-profile-img.hidden,
.edit-company-img.hidden {
  display: none;
}

.edit-company-img {
  cursor: pointer;
}

.cancel-profile-btn,
.cancel-company-btn {
  margin-left: 10px;
  font-size: 14px !important;
  background-color: #ffffff !important;
  color: #005daa !important;
}

.company-input {
  width: 100%;
}

.info-blk-dark {
  background-color: #424242;
}

.password-icons {
  margin-left: -20px;
  cursor: pointer;
}

#old_password,
#new_password,
#re_new_password {
  padding-right: 20px;
}

@media (max-width: 767px) {
  .change-password {
    width: 100%;
  }
  .profile-blk .profile-details,
  .profile-blk-dark .profile-details {
    padding-left: 0em;
  }
  .cancel-company-btn {
    margin-left: 1px !important;
  }
}

.company-op-hrs-label,
.company-op-hrs-value {
  font-size: 15px;
  font-family: "Chivo" !important;
  padding: 5px 5px 5px 0px;
}

.company-op-hrs-label {
  font-weight: bold;
}

.company-op-hrs-value p {
  margin-bottom: 0px;
}

.company-loading {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
