.previous_date_container {
  display: flex;
  justify-content: space-between;
}
.previous_date_selector {
  display: flex;
  margin-right: 10px;
}
.previous_date_selector div {
  padding: 5px 12px;
  cursor: pointer;
  font-weight: 630;
  border: 1px solid #dddddd;
  text-align: center;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.previous_date_container.light .previous_date_selector div {
  background-color: #fff;
}
.previous_date_container.dark .previous_date_selector div {
  background-color: #000;
}

.calendar_selector {
  height: 38px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.calendar_selector .react-datepicker__day--selected {
  background-color: #005daa !important;
}
.previous_date_selector div.active {
  background-color: #005daa !important;
  color: white;
  border: none;
}
.big_date_container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.cooling_report {
  background: #005daa;
  color: #fff;
  width: 250px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  outline: none;
  border: none;
  border-radius: 3px;
  margin-top: 15px;
  font-size: 14px;
  margin-left: 50px;
}
.react-calendar {
  height: 50px;
}

.calendar {
  width: 100%;
  display: flex;
  /* justify-content: center;
  align-items: center; */
  background-color: #fff;
}

.calendar_selector {
  width: 500px;
}
.calendar_selector .calendar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 12px;
}
.previous_date_container.light .calendar_selector .calendar {
  background-color: #fff;
  border-radius: 8px;
  

}
.previous_date_container.dark .sign svg path {
  fill: #fff !important;

}
.previous_date_container.dark .calendar_selector .calendar {
  background-color: #000;
  border: 1px solid white;
}
.previous_date_container.light .calendar_selector .calendar span {
  color: #000;
}
.previous_date_container.dark .calendar_selector .calendar span {
  color: #fff;
}
.calendar .sign {
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
}
.header_list {
  width: 100%;

  border-radius: 3px;
  padding: 10px;
  display: grid;
  grid-template-columns: auto auto auto auto auto auto;
  /* border: 1px solid #dddddd; */
  border-bottom: none;
  margin-top: 8px;
}
.header_list.dark {
  background-color: #000;
}
.header_list.light {
  background-color: #fff;
}
.header_list .card {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  border: none;
  height: 120px;
  background-color: transparent;
}
.card .title {
  font-weight: bold;

  font-size: 14px;
}
.header_list.dark .card.title {
  color: #fff;
}
.header_list.light .card.title {
  color: #373737;
}
.card .count {
  color: #005daa;
  font-size: 20px;
  font-weight: bolder;
  margin-right: 5px;
}
.header_list.dark .card .count {
  color: #fff;
}
.card .sign {
  color: #005daa;
  font-size: 14px;
  font-weight: 600;
}
.header_list.dark .card .sign {
  color: #fff;
}
.data_chart_container {
  width: auto;
  height: 300px;
  /* min-height: 400px; */
  background-color: #fff;
  border: 1px solid #dddddd;
  margin-top: 50px;
  padding: 15px;
  margin-right: 10px;
}
.prev_chart {
  height: 300px;
}
.data_chart_container.dark {
  background-color: #000;
}

.data_chart_container .title {
  border-left: 10px solid #00a6ef;
  color: #00a6ef;
  font-weight: bold;
  font-size: 25px;
  padding-left: 10px;
  margin-bottom: 25px;
}

.headerlist_container {
  display: flex;
  justify-content: center;
  width: 100%;
}
@media only screen and (max-width: 800px) {
  .header_list {
    /* width: 90vw; */
    width: 90% !important;
    /* margin-right: 18px; */
    grid-template-columns: auto auto;
    border-radius: 8px;
  }
  .header_list .card {
    margin-bottom: 25px;
  }
  .big_date_container {
    flex-direction: column;
  }
  .cooling_repoart_container {
    width: 100%;
  }
  .cooling_report {
    width: 88%;
    margin-top: 0px;
    /* mar */
    margin: auto;
    margin-left: 35px;
    margin-bottom: 10px;
  }
}

@media only screen and (max-width: 800px) {
  .data_container {
    width: 97% !important;
    margin: auto;
    margin-right: 20px;
  }
  .previous_date_container {
    /* width: 100vw; */
    flex-direction: column;
    justify-content: center !important;
    align-items: center;
  }

  .previous_date_selector {
    /* width: 90vw; */
    /* margin: auto; */
  }
  .previous_date_selector div {
    /* display: none; */
    width: 100vw;
    background-color: white;
    color: #000;
    padding: 5px 12px;
    cursor: pointer;
    font-weight: 630;
    border: 1px solid #dddddd;
    font-size: 11px;
    text-align: left;
  }
  .prev_dropdown_date {
    width: 90%;
    /* margin: auto;
    margin-top: 8px;
    margin-left: 25px; */
  }
  .prev_dropdown_date .dropdown {
    width: 100%;
  }
.previous_date_container.dark .prev_dropdown_date .dropdown-menu  {
  border: 1px solid #fff;
  background-color: #000;
}
  .prev_dropdown_date .dropdown button {
    width: 100%;
    text-align: left !important;
    border: none;
  }
  .prev_dropdown_date .dropdown button:focus {
    box-shadow: none;
    outline: none;
  }
  .prev_dropdown_date .btn-check:active + .btn-success,
  .btn-check:checked + .btn-success,
  .btn-success.active,
  .btn-success:active,
  .show > .btn-success.dropdown-toggle {
    border: none !important;
  }
  .prev_dropdown_date .btn-check:active + .btn-success,
  .btn-check:checked + .btn-success,
  .btn-success.active,
  .btn-success:active,
  .show > .btn-success.dropdown-toggle {
    box-shadow: none !important;
  }
  .previous_date_container div {
    border-radius: 8px;
  }
  .prev_dropdown_date .dropdown {
    border-radius: 8px;
  }
  .previous_date_container.light .prev_dropdown_date .dropdown button {
    background-color: white !important;
    color: #000;
    border-radius: 8px;
  }
  .previous_date_container.dark .prev_dropdown_date .dropdown button {
    background-color: #000 !important;
    color: #fff;
    border: 1px solid #fff;
  }
  .prev_dropdown_date .dropdown button:after {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
  }
  .prev_dropdown_date .show.dropdown div {
    width: 100%;
  }
  .previous_date_container.dark .prev_dropdown_date .show.dropdown div a {
    color: #fff;
  }
  .previous_date_container.dark .prev_dropdown_date .show.dropdown div a:hover {
    color: #000;
  }
  .calendar_selector {
    /* width: 88vw; */
    width: 90%;
    margin-top: 10px;
    /* margin: auto; */
    /* margin-top: 8px;
    margin-left: 25px; */
  }
}

.select-week .react-datepicker__week:hover .react-datepicker__day {
   
  background-color: #f0f0f0;
  
}

.react-datepicker-wrapper {
  display: inline-block;
  padding: 0;
  border: 0;
  width: 100%;
}


.custom-tooltip-data, .custom-tooltip-data-dark {
  font-size: 12px !important;
  font-family: "Chivo" !important;
}

.custom-tooltip-data-dark > .tooltip-inner {
  background-color: #C4C4C4;
  color: #0B0B0B;
  font-family: 'Chivo';
}