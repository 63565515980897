.billing_table {
  margin-top: 20px;
  border: 1px solid #dddddd;
  padding: 25px;
  background-color: #fff;
  width: auto;
  border-radius: 8px;
}
.billing_table.dark {
  background-color: #000;
}
.billing_table table tr {
  border-bottom: 1px solid #dddddd !important;
}
.billing_table table tbody tr {
  cursor: pointer;
}
.billing_table table thead {
  border-top: 1px solid #dddddd !important;
  font-size: 16px;
}

.billing_table .title {
  border-left: 10px solid #11c126;
  color: #11c126;
  font-weight: bolder;
  font-size: 25px;
  padding-left: 10px;
  margin-bottom: 25px;
}

.billing_table.light .table {
  font-family: "Chivo";
}

.billing_table.dark .table {
  --bs-table-hover-color: #fff;
  color: #fff;
  font-family: "Chivo";
}

.billing_footer {
  display: flex;
  margin-bottom: 25px;
}
.billing_footer > div {
  margin-right: 25px;
  border-right: 1px solid #dddddd;
  text-align: left;
  width: 150px;
}
.billing_footer div:nth-child(2) {
  width: 180px;
}
.billing_footer div:nth-last-child(1) {
  border: none;
}
.pdf-container-view {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 99999;
  overflow: scroll;
}
.modal_view_overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  background-color: rgba(0, 0, 0, 0.1);
  overflow: scroll;
}
.modal_header {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 40px;
  z-index: 555;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #fff;
  font-size: 18px;
  padding-right: 40px;
  font-weight: bolder;
  cursor: pointer;
}
.modal_container {
  position: absolute;
  min-width: 800px;
  height: 100%;
  background-color: white;
  z-index: 2;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -30%);
}
.invoice_table_container_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.create_invoice_button {
  width: fit-content;
  height: 50px;
  border-radius: 8px;

  color: #fff;
  cursor: pointer;
}
.create_invoice_button button {
  background-color: #11c126;
  border: none;
}
.create_invoice_button button:hover,
.create_invoice_button button:active,
.create_invoice_button button:focus {
  background-color: #11c126;
}
.create_modal_date_picker {
  display: block;
    width: 100%;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    -webkit-appearance: none;
    appearance: none;
    border-radius: 0.25rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    outline: 0 !important;
    margin-bottom: 20px;
}
.create_modal_date_picker:focus {
  color: #212529 !important;
    background-color: #fff !important;
    border-color: #86b7fe !important;
    box-shadow: 0 0 0 0.25rem rgb(13 110 253 / 25%) !important;
}
.create_modal_date_picker.date_picker_disable {
  background-color: #e9ecef !important;
}
.modalForm.dark .modal-content {
  background-color: #000;
}
.modalForm.dark .modal-content .btn-close {
  filter: brightness(0) invert(1);
}
.modalForm.dark .modal-content .form-control, .modalForm.dark .modal-content input, .modalForm.dark .modal-content .modal_attach_input_file_name {
  background-color: rgb(37, 37, 37);
  color: #fff;
}

.mobile_billing_header {
  padding: 8px 0px;
  border-bottom: 1px solid #000;
}
.mobile_billing_header.dark {
  border-bottom: 1px solid #fff;
}
@media only screen and (max-width: 800px) {
  .billing_footer {
    display: flex;
    flex-direction: column;
  }
  .billing_footer div {
    margin-right: 0px;
    margin-bottom: 8px;
    border: none;
  }
  .billing_table {
    width: 100% !important;
    margin: auto;
    margin-top: 30px;
    /* margin-left: auto;
    margin-right: 25px; */
  }
  .invoice_table_container_header {
    flex-direction: column;
  }
  .billing_table table thead {
    font-size: 12px;
  }
  .mobile_billing_container_table {
    width: 100%;
  }
  .mobile_billing_container_table .mobile_billing__table_card {
    /* width: 90%; */
    padding-bottom: 20px;
    border-bottom: 1px solid #ddd;
  }
  .billing_table table tbody {
    font-size: 12px;
  }
}
@media only screen and (max-width: 768px) {
  .billing_table {
    /* width: 90% !important; */
    width: 88% !important;
    margin-right: 30px;
  }
  .billing_table .title {
    font-size: 18px;
  }
}
