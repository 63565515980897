.contact-blk {
  min-height: 20vh;
  padding-top: 20px;
  border: 1px solid #DDDDDD;
  background-color: #FFFFFF !important;
}

.contact-blk-dark {
  min-height: 20vh;
  padding-top: 20px;
  border: 1px solid #DDDDDD;
  background-color: #0B0B0B !important;
}

.contact-main-blk-dark {
  background-color: #0B0B0B !important;
}

.contact-padding-blk {
  background-color: #005DAA;
  width: 8px;
  height: 33px;
}

.contact-blk-header {
  vertical-align: middle;
  padding-left: 0.5em;
  color: #005DAA;
  font-weight: 600;
}

.contact-blk .info-blk p, .contact-blk-dark .info-blk p {
  font-size: 15px !important;
}

.info-blk {
  background-color: #F8F8F8;
}

.info-blk .info-title {
  font-weight: bold;
}

.info-blk-dark {
  background-color: #0B0B0B;
  border: 1px solid #F8F8F8;
}

.contact-info p {
  line-height: 0.8em;
  font-size: 15px;
}

.contact-info img {
  float: right;
  padding-left: 10px;
  padding-bottom: 0.8em;
  width: 35px;
  cursor: pointer;
}

.emer-blk {
  /* display: inline-block; */
}

.emer-left-blk {
  text-align: center;
  margin: 0 auto;
  width: 170px;
  vertical-align: middle;
  height: 100%;
  margin-right: 10px;
  float: left;
}

.emer-right-blk {
  line-height: 1.5em;
  /* width: calc(100% - 180px); */
  font-size: 15px !important;
}

.info-divider {
  border: 1px solid #DDDDDD;
  margin-right: 10px;
  margin-left: 5px;
}

.gmap-wrapper {
  height: 400px;
}

.contact-company-name {
  font-size: 18px !important;
  font-weight: 600;
}

.contact-company-address {
  font-size: 15px !important;
}

@media (max-width: 992px) {
  .contact-company-mobile {
    line-height: 2em;
  }
}

@media (max-width: 1062px) {
  .contact-blk .info-blk {
    min-height: 203px;
  }
}
